.sidebar {
    position: fixed;
    top: 0;
    width: 24.5%;
    min-height: 100vh;
    background-color: #343a40;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: .5% solid #f9f9fe;

    a {
        color: #f9f9fe;
        text-decoration: none;
        font-size: calc(1rem + 1.2vw);
        font-weight: bold;
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.sidebar-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}