#intro {
    background-color: #343a40;
    position: flex;
    min-height: 100vh;
}

.intro-content {
    padding-top: 1rem;
    position: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #f9f9fe;
    font-weight: bold;

    img {
        border-radius: 50%;
        width: calc(23vw + 23vh);
    }
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .social a {
        margin: 0 1rem;
        color: #f9f9fe;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }
}
